




























































import { Embarcacao } from "@/core/models/operacional";
import { PageBase } from "@/core/models/shared";
import { EmbarcacaoService } from "@/core/services/operacional";
import { AlertExcludeQuestion, AlertSimpleErr } from "@/core/services/shared/AlertService";
import { Component, Prop, Watch} from "vue-property-decorator";

@Component
export default class ListaEmbarcacao extends PageBase {
    search: any = '';
    loading: boolean = false;
    dialogCadastro: boolean = false;
    totalLista: number = 0;
    lista: any[] = [];
    options: any = {
        itemsPerPage: 15
    };
    headers: any[] = [
        { text: '',value:'actions' ,sortable: false },
        // { text: 'Foto', value: 'foto', type:'string'},
        { text: 'Nome', value: 'nome' },
        { text: 'Tipo', value: 'tipo.nome' },
        { text: 'Marine Traffic', value: 'link' },
    ];

    item = new Embarcacao();
    service = new EmbarcacaoService();

    @Watch('options', { deep: true })
    Atualizar(){
        const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
        this.loading = true;
        this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, undefined, "Tipo").then(
        res => {
            this.lista = res.data.items;
            this.totalLista = res.data.count;
        },
        err => {
            AlertSimpleErr("Aviso!", err)
        })
        .finally(() => (this.loading = false));
    }

    AbrirDialogCadastro(item?: Embarcacao){
        if(item){
            this.service.ObterPorId(item.id).then(
                res=>{
                    this.item = res.data;
                    this.dialogCadastro = true;
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
        else{
            this.item = new Embarcacao();
            this.item.empresaId = this.app.empresaId;
            this.dialogCadastro = true;
        }
    }

    Excluir(item: Embarcacao){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                await context.service.Excluir(item.id).then(
                    res => {
                        if(res.status == 200){
                            return resolve(res.data);
                        }
                    },
                    err => {
                        AlertSimpleErr("Aviso", err);
                    }
                ).finally(() => {
                    context.Atualizar();
                })
            });
        }
        AlertExcludeQuestion(excluir);
    }
}
